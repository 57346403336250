export function clearNumber(value = '') {
    return value.replace(/\D+/g, '')
}

// Esta funcion me ayuda a darle el formato XXXX XXXX XXXX XXXX
export function formatCreditCardNumber(value) {
    if (!value) {
      return value
    }
  
    const clearValue = clearNumber(value)
    let nextValue;
    nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
    )} ${clearValue.slice(8, 12)} ${clearValue.slice(12,16)}`;
  

    return nextValue.trim()
  }

//Esta funcion pasa de XXXX XXXX XXXX XXXX a XXXXXXXXXXXXXXXX
export const unformatCreditCard = (val) => {
    let r = val.split(' ');
    return r.join('');
}


export const expirationMonths = [
  "01","02","03","04","05","06","07","08","09","10","11","12"
]

export const expirationYear = [
 "20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35"
]




