import { SwalAlert } from "alerts/alerts";

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {Card,CardHeader,CardBody,NavItem,NavLink,Nav,TabContent,TabPane,Container,Row,Col,Alert} from "reactstrap";
import Button from "reactstrap/lib/Button";
import { GetDebtForCash } from "store/payment/trunks";
import { BodyForm } from "./BodyForm/BodyForm";
import {CardHeaders} from "./CardHeaders/CardHeaders"

import { FormularioTarjeta } from "./FormularioTarjeta/FormularioTarjeta";
import './metodo-pago.css';
// core components


function MetodoPago() {

  const [iconPills, setIconPills] = React.useState("1");
  
  //Estos estados los usamos para mostrar la operacion en el comprobante  
  const history = useHistory();
  const [generandoLink,setGenerandoLink] = useState(false);

  const dispatch = useDispatch();
  const { selectedClient} = useSelector(state => state.client);
  const { pagoRealizado,cashLink } = useSelector(state => state.payment);
  
  
  //Esta funcion redirecciona al cliente a la plataforma de pagos 360 para que abone en efectivo
 
  
 
  useEffect(() => {
    if(pagoRealizado){
      history.push('/comprobante');
    }
  }, [pagoRealizado]);


  //This use Effect is used to redirect the user to the payment platform 360
  useEffect(() => {
    if(cashLink){
      window.location.href = cashLink;
    }
  },[cashLink])

  
  return (
    <>
       

          <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10" xl="8">
              
              <Card>

                <CardHeaders iconPills={iconPills} setIconPills={setIconPills}></CardHeaders>
                <BodyForm iconPills={iconPills}></BodyForm>
            
              </Card>



                        
            </Col>
           
          </Row>
         
        </Container>
  
        
        
    
        
    
    </>
  );
}

export default MetodoPago;
