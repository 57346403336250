

import React, { memo, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {Card,CardHeader,CardBody,NavItem,NavLink,Nav,TabContent,TabPane,Container,Row,Col,Alert} from "reactstrap";
import Button from "reactstrap/lib/Button";
import { canPayInCashTrunk } from "store/client/trunks";
import { GetDebtForCash } from "store/payment/trunks";
import { FormularioTarjeta } from "../FormularioTarjeta/FormularioTarjeta";


export const BodyForm = memo(({iconPills}) => {

 const dispatch = useDispatch();
 const {canPayInCash,selectedClient} = useSelector(state => state.client);
 const {isLoading} = useSelector(state => state.payment);

  const handleClickPagar = () => {
    dispatch(GetDebtForCash());
  }




  useEffect(() => {
    dispatch(canPayInCashTrunk(selectedClient.client))
  },[])

  return (
    <>
            <CardBody>
                  <TabContent
                    className="text-center"
                    activeTab={"iconPills" + iconPills}
                  >
                    <TabPane tabId="iconPills1">
                          <FormularioTarjeta/>   
                    </TabPane>
                    <TabPane tabId="iconPills2">
                          <FormularioTarjeta/>
              
                    </TabPane>
                    <TabPane tabId="iconPills3">

                      {

                        !canPayInCash ?
                             <div className="text-center">
                             <h2>Podra abonar en nuestras oficinas:</h2>
                             <h3>🏠 Independencia 936, <b>Zarate</b></h3>
                             <h3>🏠 San Martin 1007, <b>Baradero</b></h3>
                             <h3></h3>
                             
                           </div>
                          
                           
                           :
                           <Button className="btn-round btn-primary btn-lg" color="default" outline size="lg" onClick={handleClickPagar}>Pagar</Button>


                      }

<br/>
                           <br/>
                           <br/>
                 
                      {
                        isLoading?
                        <Alert color="info">
                          Generando Link
                        </Alert>
                        :null
                      }
                   
                    
                    </TabPane>
                  
                  </TabContent>
                  
                  
                </CardBody>
    
    </>
  )
})
