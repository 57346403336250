import {url,url2} from "../config/configEnv";

export const getServices = async(dni) => {
    //let dni = 'A2288';
    let resp = await fetch(`${url}/servicios/pagar/${dni}`);
    
    let data = await resp.json();
  
    return data;
}


export const getLinkPagoServ = async(serviceId) => {
    const nResp = await fetch(`${url}/servicios/generarLinkEfectivo/${serviceId}`);
    const nData = await nResp.json();

    return nData;
}


export const getDebt = async(dni) => {
    try{
        let resp = await fetch(`${url2}/public/facturas/pagar/${dni}`);
        let data = await resp.json();
        
        return data;
    }
    catch(error){
        return false
    }
}

export const getDebtForCash = async(id) => {
    try{
        let resp = await fetch(`${url2}/public/facturas/getDebtForCash/${id}`);
        console.log(resp);
        let data = await resp.json();
        
        return data;
    }
    catch(error){
        console.log(error);
        return false
    }
}

export const GenerateCashLink = async(clientId,amount) => {
    try{
        let body = {clientId,amount}
        let headers = {'Content-Type':'application/json'}
        let resp = await fetch(`${url2}/public/facturas/generarLinkEfectivo`,{headers,body,method:'POST'})
        
        const data = await resp.json();
        return data;
    }
    catch(error){
        console.log(error);
        return false;
    }
    
}