import React, { useContext, useEffect, useState } from "react";
import imageCheck from '../../assets/img/successCheck.png';
import Moment from 'react-moment';
import {
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Container,
    Row,
    Col,
    Button
  } from "reactstrap";
import DarkFooter from "components/Footers/DarkFooter";
import "./ComprobanteCss.css"
import ExamplesNavbar from "components/Navbars/ExamplesNavbar";
import { useDispatch, useSelector } from "react-redux";




function Comprobante() {

  const [isPrinting,setIsPrinting] = useState(false);
  const dispatch = useDispatch();
  const {payment} = useSelector(state => state.payment);
  const {selectedClient} = useSelector(state => state.client);
  const {id,site_transaction_id,amount,paymentId,card_brand} = payment.resp;



  const handlePrint = () => {

    setIsPrinting( oldState => {
      setIsPrinting(true);
      //Este timeout lo pongo porque sino react no llega a quitar los componentes que quiero ocultar antes de tirar la impresion
      setTimeout(function(){ 
        imprimir();
      }, 100);
      
    });
 
  }

  const imprimir = () => {
    
    window.print();
    setIsPrinting(false);

  }

  const handleAmount = () => {
    let aStr = amount.toString();
    return aStr.slice(0,-2);
  }
  useEffect(() => {
    console.log(payment);
    
  }, []);
  

  return (
    <>

   <Container className="fondoColor">
          <Row>
            <Col className="ml-auto mr-auto" md="10" xl="8">
              
              <Card>
                <CardHeader>

                <Row>
                  <Col className="ml-auto mr-auto" xs="12" sm="12" md="4" lg="4">

                  </Col> 
                  <Col className="ml-auto mr-auto text-center" xs="12" sm="12" md="4" lg="4">

                
                    <img src={imageCheck}></img>
                    <br/>
                    <br/>

                    <h3 className="colorLetra">Su pago ha sido aprobado</h3>
                    <p className="colorLetra">Cliente : {selectedClient.client}</p>
                    <p className="colorLetra">Pago: {paymentId}</p>
                    <p className="colorLetra">ID operación:</p>
                    <p className="colorLetra">{id}</p>
                    <p className="colorLetra">
                    <Moment format="DD/MM/YYYY">
                      {new Date()}
                  </Moment>

                    </p>
                    <p className="colorLetra">Tarjeta: {card_brand}</p>
                    
                    
                    <p className="colorLetra">Importe: $ { handleAmount() }</p>    
                    <br/>
                    <br/>

      
                      {
                      
                          !isPrinting ?
                          <Button block className="btn-round" color="info" onClick={handlePrint} size="lg"> Imprimir </Button>  
                          
                          :null

                      }
                        
                  </Col> 
                  <Col className="ml-auto mr-auto" xs="12" sm="12" md="4" lg="4">

                  </Col> 
                  
                </Row>
                    
                </CardHeader>
               
              </Card>



                        
            </Col>
           
          </Row>
        </Container>

      
    
      
      
    
   
  </>

  );
}

export default Comprobante;