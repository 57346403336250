import {publicKey, urlDecidir,cardsTypesIds, url,url2} from "../config/configEnv";


/*
card_number, card_expiration_month ,card_expiration_year ,security_code ,
card_holder_name ,
card_holder_identification: { type: "dni", number: }

Para formar este obj usamos la funcion que esta debajo : "generateDecicirTokenObj"
*/
export const generateToken = async(d) => {
    let body = JSON.stringify(d);
    let headers = {'Content-Type':'application/json','apiKey':publicKey};
    const resp = await fetch(`${urlDecidir}/tokens`,{headers,body,method:'POST'});
    
    const data = await resp.json();
  
    return data;
}


//Esta funcion se encarga de mandar los datos a la api para que genere el pago
export const generarPago = async(tokenObject, price, clientId, idMetodoPago) => {
    let amount = parseInt(((price).toString()) + '00');
    let d = {token:tokenObject.id,amount,clientId:clientId, bin: tokenObject.bin, payment_method_id: idMetodoPago}
  
    let body = JSON.stringify(d);
    let headers = {'Content-Type':'application/json'};
    const resp = await fetch(`${url2}/decidir/pagos`,{headers,body,method:'POST'});
    
    const data = await resp.json();
  
    return data;
}





export const seleccionarIdTipoTarjeta = (nombre,isDebit) => {
    let id = null;
    cardsTypesIds.forEach(tarjeta => {
      if(tarjeta.nombre === nombre){
        if(isDebit){
          id = tarjeta.debito;
        }
        else{
          id = tarjeta.credito
        }
      }
    })
    return id;
}



//Esta funcion se encarga de moldear el objeto como debe ser pasado a la api de Decidir
export const generateDecicirTokenObj = (d) => {
  console.log(d);
  return {
    card_number:d.card_number, 
    card_expiration_month:d.card_expiration_month,
    card_expiration_year:d.card_expiration_year,
    security_code:d.security_code,
    card_holder_name:d.card_holder_name,
    card_holder_identification:{ 
      type: "dni", number:d.card_holder_identification
    }
  }
}


  
  
      
      







