import React, { useState } from "react";
import { Provider, useSelector } from "react-redux";
import { store } from "./store";
import { AppRouter } from "router/AppRouter";


export const RouterComponent = () => {
   
 

    return(
        <Provider store={store}>
            <AppRouter></AppRouter>

        </Provider>
       
     
      
        
    );
}

