import React from 'react'

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";


// pages for this kit
import Index from "views/Index.js";
import { Provider, useSelector } from "react-redux";

import { AuthenticatedRoutes } from "router/AuthenticatedRoutes";
import MetodoPago from 'views/metodo-pago/metodo-pago';
import Comprobante from 'views/Comprobante.js/Comprobante';

export const AppRouter = () => {
    const {selectedClient} = useSelector(state => state.client);
  return (
        <BrowserRouter>
        <Switch>
        <Switch>
        
               
                <Route path="/index" render={(props) => <Index {...props} />} />
                <Route path="/metodo-pago" render={(props) => <MetodoPago {...props} />}/>
                <Route path="/comprobante" render={(props) => <Comprobante {...props} />}/>
        



            <Redirect to="/index" />
            <Redirect from="/" to="/index" />
        </Switch>
        </Switch>
    </BrowserRouter>
  )
}
