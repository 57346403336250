import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setIsDebit } from 'store/payment/paymentSlice';
import {Card,CardHeader,CardBody,NavItem,NavLink,Nav,TabContent,TabPane,Container,Row,Col,Alert} from "reactstrap";

export const CardHeaders = ({iconPills,setIconPills}) => {

  
  const dispatch = useDispatch();
  const { selectedClient} = useSelector(state => state.client);
  
  const handleCardChange = (type) =>{
    if(type==='debit'){
        dispatch(setIsDebit(true))
    }
    else{
        dispatch(setIsDebit(false))
    }

  }


  return (
    <>
         <CardHeader>
                  <h2 className="text-center letraTexto">$ {selectedClient.deuda}</h2>
                  <h2 className="text-center letraTexto">¿ Como deseas abonar ?</h2>
                  <Nav className="justify-content-center" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={iconPills === "1" ? "active" : ""}
                        
                        onClick={(e) => {
                          e.preventDefault();
                          handleCardChange('debit');
                          setIconPills("1");
                        }}
                      >
                        <i className="now-ui-icons objects_umbrella-13"></i>
                        Tarjeta Debito
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "2" ? "active" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          handleCardChange('credit');
                          setIconPills("2");
                        }}
                      >
                        <i className="now-ui-icons shopping_cart-simple"></i>
                        Tarjeta Credito
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "3" ? "active" : ""}
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("3");
                        }}
                      >
                        <i className="now-ui-icons shopping_shop"></i>
                        Efectivo
                      </NavLink>
                    </NavItem>
                
                  </Nav>
                </CardHeader>
    </>
  )
}
