import { unformatCreditCard } from "helpers/cardUtils";
import { seleccionarIdTipoTarjeta } from "services/decidirService";
import { generateDecicirTokenObj } from "services/decidirService";
import { generarPago } from "services/decidirService";
import { generateToken } from "services/decidirService";
import { getDebtForCash } from "services/planesService"
import { setCashLink, setError, setIsLoading, setPagoRealizado, setPayment } from "./paymentSlice";

export const GetDebtForCash = () => {
    
    return async (dispatch, getState) => {
        try{
            dispatch(setIsLoading(true));

            let {selectedClient} = getState().client; 
            let {deuda,client} = selectedClient;
            let data = await getDebtForCash(client,deuda);
            console.log(data);
            if(data.ok){
               let arraySplit = data.data.split('/');
               let tokenPago = arraySplit[arraySplit.length - 1];
               dispatch(setCashLink(`https://api.pagos360.com/payment-request/pdf/${tokenPago}`))
            }
            else{
                dispatch(setError("No se ha podido generar el link de pago"));
            }
        }
        catch(error){
            dispatch(setError("No se ha podido generar el link de pago"));
        }
        finally{
            dispatch(setIsLoading(false));
        }
       
    }
    
}


export const generatePayment = (values) => {

    return async (dispatch, getState) => {
        try{
            const isDebit = getState().payment.isDebit;
            const deuda = getState().client.selectedClient.deuda;
            const client = getState().client.selectedClient.client;

            dispatch(setIsLoading(true));
            let idMetodoPago = seleccionarIdTipoTarjeta(values.card_type,isDebit);

            let tokenObj = generateDecicirTokenObj(values);
            let data = await generateToken(tokenObj);
          
           
            let pago = await generarPago(data,deuda,client,idMetodoPago);
            console.log("Pagoo",pago);
            if(pago.ok){
                dispatch(setPagoRealizado(true));
                dispatch(setPayment(pago));
               
            }

            // Si no se pudo generar el pago pruebo con el metodo de pago
            else{
                let nueboIdMetodoPago = seleccionarIdTipoTarjeta(values.card_type,!isDebit);
                pago = await generarPago(data,deuda,client,nueboIdMetodoPago);
                if(pago.ok){
                    dispatch(setPagoRealizado(true));
                    dispatch(setPayment(pago));
                    console.log("Pagoo2",pago);
                }
                else{
                    dispatch(setError("No se ha podido generar el pago"));
                }
                
            }
            

        }
        catch(error){
            dispatch(setPagoRealizado(false));
            dispatch(setError(error));
        }
        finally{
            dispatch(setIsLoading(false));
        }





    }

    
    


}