import React, { useState } from 'react';
import { Card, Button, Form, Col, Row, Alert } from 'react-bootstrap';
import swal from 'sweetalert';


export function SwalAlert(titulo, texto, tipo) {
  
    swal({
      title: titulo,
      text: texto,
      icon: tipo,
    })
  }
  