

//export const url = 'http://54.212.66.56:3002/api';
//export const urlDecidir = 'https://developers.decidir.com/api/v2';
//export const publicKey = 'e9cdb99fff374b5f91da4480c8dca741';
//export const urlPagos = 'https://api.pagos360.com/payment-request/pdf';
//export const urlApiPagos = 'http://localhost:3001/api';



export const url = 'https://sistema.globalnet.net.ar/api';
export const url2 = 'https://apiv2.globalnet.net.ar/api';
export const urlApiPagos = 'https://apipagos.globalnet.net.ar/api';
export const urlDecidir = 'https://live.decidir.com/api/v2';
export const publicKey = 'e84d783e93d84561ad33f96cb51acec6';
export const urlPagos = 'https://api.pagos360.com/payment-request/pdf';


export const cardsTypesIds = [
    {nombre:'visa',debito:31,credito:1},
    {nombre:'mastercard',debito:105,credito:104},
    {nombre:'cabal',debito:108,credito:63}
]