import { SwalAlert } from "alerts/alerts.js";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar";
import { useFetchServices } from "hooks/useFetchServices.js";

import React, { useContext, useEffect, useRef, useState } from "react";


// reactstrap components
import {Button,Card,CardHeader,CardBody,CardFooter,Form,Input,InputGroupAddon,InputGroupText,InputGroup,Container,Col,Alert} from "reactstrap";
import { useForm } from "../../hooks/useForm.js";
import {useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { SearchClient } from "store/client/trunks.js";



function BuscadorDni() {
  
  const [firstFocus, setFirstFocus] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading,selectedClient,notFound } = useSelector(state => state.client);

  const {dni,onInputChange} = useForm({
    dni:''
  })

  const handleClick = async () => {
    dispatch(SearchClient({dni:parseInt(dni)}));
  }

  useEffect(() => {
    if(selectedClient){
      
      history.push('/metodo-pago');
    }
    console.log("Selcted");

    if(notFound){
      SwalAlert("Advertencia",'No se ha podido encontrar el cliente', "warning");
    }
    
  }, [selectedClient,notFound]);

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  

    return (
      <>
        <ExamplesNavbar />
        <div className="page-header clear-filter" filter-color="blue">
          <div
            className="page-header-image"
            style={{
              backgroundImage: "url(" + require("assets/img/login.jpg") + ")",
            }}
          ></div>
          <div className="content">
            <Container>
              <Col className="ml-auto mr-auto" md="4">
                <Card className="card-login card-plain">
                  <Form>
                    <CardHeader className="text-center">
                      <div className="logo-container">
                        <img
                          alt="..."
                          src={require("assets/img/now-logo.png")}
                        ></img>
                      </div>
                    </CardHeader>
                    <CardBody>
                  {
                    isLoading?
                    <Alert color="info">
                    Buscando Cliente
                  </Alert>
                  :null
                   
                  }
                  
                
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08"></i>
                          </InputGroupText>
                        </InputGroupAddon>
            
                        
                        <input type="text" name='dni' className="form-control" placeholder="Ingrese su dni" value={ dni } onChange={ onInputChange }></input>
                      </InputGroup>
                  
                    </CardBody>
                    <CardFooter className="text-center">
                   
                      <Button
                        block
                        className="btn-round"
                        color="info"
                        onClick={(e) => e.preventDefault()}
                        size="lg"
                        onClick={handleClick}
                        disabled={isLoading}
                      > 
                        Buscar
                      </Button>
             
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Container>
          </div>
        
        </div>
      </>
    );
  
  
  
                    
  
  
 
}

export default BuscadorDni;