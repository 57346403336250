import { url2 } from "config/configEnv";

export const CanPayInCash = async(id) => {

    let headers = {'Content-Type':'application/json'};
    const resp = await fetch(`${url2}/public/servicios/puedePagarEfectivo/${id}`,{headers,method:'GET'});
    
    const data = await resp.json();
  
    return data;

    
}