import { createSlice } from '@reduxjs/toolkit';
export const paymentSlice = createSlice({
       name: 'payment',
       initialState: {
           payment: null,
           cashLink:null,
           isLoading: false,
           pagoRealizado:false,
           isDebit : true,
           error:null
       },
       reducers: {
            setPayment: (state, action) => {
                state.payment = action.payload;
            },
            setCashLink: (state, action) => {
                state.cashLink = action.payload;
            },
            setIsLoading: (state, action) => {
                state.isLoading = action.payload;
            },
            setPagoRealizado: (state, action) => {
                state.pagoRealizado = action.payload;
            },
            setError: (state, action) => {
                state.error = action.payload;
            },
            setIsDebit: (state, action) => {
                state.isDebit = action.payload;
            }
       }
});
export const { setPayment,setCashLink,setIsLoading,setError,setPagoRealizado,setIsDebit } = paymentSlice.actions;