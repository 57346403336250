import { CanPayInCash } from "services/clientsService";
import { getDebt } from "services/planesService";
import { setCanPayinCash, setClient, setError, setIsLoading, setNotFound } from "./clientSlice";


export const SearchClient = ({dni}) => {

    return async (dispatch, getState) => {
        dispatch(setIsLoading(true));
        dispatch(setNotFound(false));
        try{
            let data = await getDebt(dni);
            
            if(data.ok){
                let {deuda,client,pagoMinimo} = data;
                dispatch(setClient({deuda,client,pagoMinimo}));
                
            }
            else{
                dispatch(setNotFound(true))
            }
        }
        catch(error){
           dispatch(setError(true))
        }
        finally{
            dispatch(setIsLoading(false));
        }
    }
}

export const canPayInCashTrunk = (id) => {
    return async (dispatch, getState) => {
        try{
            let data = await CanPayInCash(id);
            dispatch(setCanPayinCash(data.ok));

        }
        catch(error){
            dispatch(setError(error));
        }
    }
}