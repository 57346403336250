import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input,Alert } from 'reactstrap';
import { SwalAlert } from 'alerts/alerts';
import { unformatCreditCard,expirationMonths,expirationYear } from 'helpers/cardUtils';
import './FormularioTarjeta.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { generatePayment } from 'store/payment/trunks';
import { setError } from 'store/payment/paymentSlice';

// Props isDebit determina si el usuario selecciono trarjeta de debito o de credito
export const FormularioTarjeta = () =>{

    const dispatch = useDispatch();
    const {isLoading,error} = useSelector(state => state.payment);
    const {canPayInCash} = useSelector(state => state.client);
  
    const cardTypes = [
      {name:'Visa',value:'visa'},
      {name:'Mastercard',value:'mastercard'},
      {name:'Cabal',value:'cabal'}
    ]

  

    const {getFieldProps,handleSubmit,errors} = useFormik({
      initialValues:{
        card_number:'',
        card_holder_name:'',
        security_code:'',
        card_expiration_month:'',
        card_expiration_year:'',
        card_type:'',
        card_holder_identification: ''
      },onSubmit:(values) => {
        dispatch(generatePayment(values));

      },validationSchema:Yup.object({
        card_number:Yup.string()
          .required('El numero de tarjeta es requerido')
          .matches(/^[0-9]{16}$/,'El numero de tarjeta debe tener 16 digitos'),
        card_holder_name:Yup.string()
          .required('El nombre del titular es requerido'),
        security_code:Yup.string()
          .required('El codigo de seguridad es requerido')
          .matches(/^[0-9]{3}$/,'El codigo de seguridad debe tener 3 digitos'),
        card_expiration_month:Yup.string()
          .required('El mes de expiracion es requerido'),
        card_expiration_year:Yup.string()
          .required('El año de expiracion es requerido'),
        card_type:Yup.string()
          .required('El tipo de tarjeta es requerido'),
        card_holder_identification:Yup.string().matches(/^[0-9]*$/,'El dni debe tener solo numeros').required('El dni es requerido'),
      }),
    })

    useEffect(() => {
      if(error){
        SwalAlert('Error',error,'error');
        dispatch(setError(null));
      }
    },[error])

  

    
        return(
            <div onSubmit={handleSubmit}>
            <Form>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleEmail" className="letraTexto">Numero de Tarjeta</Label>
                  <Input type="text" {...getFieldProps('card_number')} placeholder="XXXX XXXX XXXX XXXX" maxLength="16"/>
                  {errors.card_number && <span>{errors.card_number}</span>}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="examplePassword" className="letraTexto">Nombre del Titular</Label>
                  <Input type="text" {...getFieldProps('card_holder_name')} placeholder="Ej: Juan Rodriguez"/>
                  {errors.card_holder_name && <span>{errors.card_holder_name}</span>}
                </FormGroup>
              </Col>
            </Row>
    
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleEmail" className="letraTexto">Codigo de Verificacion</Label>
                  <Input type="text" {...getFieldProps('security_code')} placeholder="XXX" maxLength="3"/>
                  {errors.security_code && <span>{errors.security_code}</span>}
                </FormGroup>
              </Col>
              <Col md={3}>
              <Label for="exampleSelect" className="letraTexto">Mes de Expiracion</Label>
            <Input type="select" {...getFieldProps('card_expiration_month')}>
              <option value="00">--</option>
              {
                   expirationMonths.map(month => {
                    return(
                      <option key={`month-${month}`} value={month}>{month}</option>
                    ); 
                    
                  })
              }
           
            
            </Input>
            {errors.card_expiration_month && <span>{errors.card_expiration_month}</span>}
    
                
              </Col>
    
    
              <Col md={3}>
              <Label for="exampleSelect" className="letraTexto">Año de Expiracion</Label>
                <Input type="select" {...getFieldProps('card_expiration_year')}>
                <option value="00">--</option>
                {
                    expirationYear.map(year => {
                      return(
                        <option key={`month-${year}`} value={year}>{year}</option>
                      ); 
                      
                    })
                }
        
                </Input>
                {errors.card_expiration_year && <span>{errors.card_expiration_year}</span>}
    
                
              </Col>
    
    
            </Row>
    
    
            <Row>
            <Col md={6}>
              <Label for="exampleSelect" className="letraTexto">Tipo de Tarjeta</Label>
                <Input type="select" {...getFieldProps('card_type')}>
                <option>---</option>
                {
                    cardTypes.map(type => {
                      return(
                        <option key={`type-${type.value}`} value={type.value}>{type.name}</option>
                      ); 
                      
                    })
                }
              
             
                </Input>
                {errors.card_type && <span>{errors.card_type}</span>}
    
                
              </Col>
    
              <Col md={6}>
                <FormGroup>
                  <Label for="exampleEmail" className="letraTexto">Dni del Titular</Label>
                  <Input type="text" {...getFieldProps('card_holder_identification')} placeholder="Ej 4000000"/>
                  {errors.card_holder_identification && <span>{errors.card_holder_identification}</span>}
                </FormGroup>
              </Col>
    
    
            </Row>
    
          
           
            
            <Button block className="btn-round" color="info" type="submit" size="lg" disabled={isLoading}>Abonar</Button>
            
          </Form>
          <br/>
          <br/>
         
          <div className="text-center">
        
          </div>
          
          
          <br/>
          <br/>
          <br/>
          <br/>
          </div>
    
           
    
        );
    
 

  

  
    
}